*,*::after,*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  overflow: auto;
}

@font-face {
  font-family: MavenPro;
  src: url("https://i.wlycdn.com/wealthy-fonts/maven-pro-regular.woff") format("woff")
}

@font-face {
  font-family: MavenPro-Bold;
  src: url("https://i.wlycdn.com/wealthy-fonts/maven-pro-extra-bold.woff") format("woff")
}

@font-face {
  font-family: DMSerif-Italic;
  src: url("https://i.wlycdn.com/wealthy-fonts/dm-serif-italic.woff") format("woff")
}

@font-face {
  font-family: DMSerif;
  src: url("https://i.wlycdn.com/wealthy-fonts/dm-serif-regular.woff") format("woff")
}

@import url('https://fonts.googleapis.com/css2?family=Marcellus&display=swap');

body {
  line-height: 1.7;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif, "MavenPro", "MavenPro-Bold", "DMSerif-Italic", "DMSerif";

  overflow: scroll;

  -ms-overflow-style: none; /* IE 11 */
    scrollbar-width: none; /* Firefox 64 */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  display: none !important;
}
